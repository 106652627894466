import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DialogTitleWithClose, ISupportCase, ISupportCaseFoodRestriction } from '../../../shared';
import { useUpdateFoodRestrictions } from '../../hooks';
import { SupportCaseFoodRestrictionInputs } from '../support-case-food-restriction-inputs/support-case-food-restriction-inputs.component';
import { supportCaseFoodRestrictionsFromFormMapper, supportCaseFoodRestrictionsToFormMapper } from '../../mappers';
import { useSupportCaseFoodRestrictionsSchema } from '../../validators';

interface Props {
    open: boolean;
    supportCase: ISupportCase;
    handleClose: () => void;
}

export const UpdateFoodRestrictionsModal: FC<Props> = ({ open, supportCase, handleClose }) => {
    const { t } = useTranslation();
    const { mutateAsync: updateFoodRestrictions, isPending: isSaving } = useUpdateFoodRestrictions(supportCase.id);

    const form = useForm<{ foodRestrictions: ISupportCaseFoodRestriction[] }>({
        resolver: yupResolver(useSupportCaseFoodRestrictionsSchema()),
        mode: 'all',
    });

    useEffect(() => {
        supportCase && form.reset(supportCaseFoodRestrictionsToFormMapper(supportCase));
    }, [supportCase]);

    const onSubmit = async (data: { foodRestrictions: ISupportCaseFoodRestriction[] }) => {
        await updateFoodRestrictions(supportCaseFoodRestrictionsFromFormMapper(data));
        handleClose();
    };

    return (
        <Dialog open={open} fullWidth maxWidth="sm">
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <DialogTitleWithClose onClose={handleClose}>{t('editFoodRestriction')}</DialogTitleWithClose>
                    <DialogContent>
                        <SupportCaseFoodRestrictionInputs supportees={supportCase.supportees} form={form} />
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit" variant="contained" color="primary" disabled={isSaving}>
                            {t('save')}
                        </Button>
                        <Button onClick={handleClose} color="secondary">
                            {t('cancel')}
                        </Button>
                    </DialogActions>
                </form>
            </FormProvider>
        </Dialog>
    );
};
