export const registrationTranslationsNL = {
    activeSupportPeriods: 'Actieve hulpperiodes',
    adults: 'Volwassenen',
    ageUnknown: 'Leeftijd onbekend',
    cancelRegistration: 'Annuleer registratie',
    children: 'Kinderen',
    codeLengthError: 'Code moet bestaan uit 8 karakters',
    comment: 'Commentaar',
    createRegistrationError: 'Registratie niet succesvol',
    createRegistrationSuccess: 'Registratie succesvol',
    emptyStateActiveSupportPeriods: 'Er zijn geen actieve hulpperiodes.',
    emptyStateRegistrations: 'Dit hulpdossier heeft nog geen hulpregistraties',
    backToRegistrationPage: 'Terug naar de registratiepagina',
    registrations: 'Registraties',
    noSupportCaseFound: 'Geen hulpdossiers gevonden',
    noSupportCaseFoundForCode: 'Geen hulpdossier gevonden voor deze code',
    register: 'Registreer',
    registration: 'Registratie',
    searchSupportCase: 'Zoek hulpdossier',
    searchSupportCaseDescription: 'Zoek naar een specifiek hulpdossier.',
    startRegistration: 'Start registratie',
    confirmRegistration: 'Bevestig registratie',
    confirmSupportCase: 'Bevestig hulpdossier',
    unconfirmedSupportCase: 'Onbevestigd hulpdossier',
    unconfirmedSupportCaseWarning:
        'Voordat je voor dit dossier registraties kan toevoegen moet je het dossier eerst bevestigen. Het systeem controleert vervolgens automatisch of er eventuele dubbels zijn. Daarna kan je een registratie toevoegen.',
    noPermissionsToConfirm: 'Geen rechten',
    noPermissionsToConfirmWarning: 'Je hebt niet voldoende rechten om dit dossier te bevestigen.',
    confirmSuccessMessage: 'Dossier succesvol bevestigd',
    confirmErrorMessage: 'Er is een probleem opgetreden tijdens het bevestigen van het dossier.',
    supportType: 'Hulptype',
    supportTypeRequired: 'Hulptype is verplicht.',
    commentTooLarge: 'Het commentaar veld mag maximum 240 karakters bevatten.',
    yearOld: 'jaar oud',
    or: 'of',
    code: 'Code',
    typeCode: 'Code ingeven',
    scanCode: 'Code scannen',
    scanDescription: 'Gebruik de scanner om de QR code op de kaart te scannen.',
    selectSupportCaseTitle: 'Selecteer hulpdossier',
    selectSupportCaseText: 'Selecteer het hulpdossier waarop je de registratie wilt doen.',
    addRegistration: 'Voeg registratie toe',
    showMoreAttributes: 'Toon meer attributen',
    showLessAttributes: 'Verberg attributen',
    deleteRegistrationSuccess: 'Registratie succesvol verwijderd.',
    deleteRegistrationError: 'Er is een probleem opgetreden tijdens het verwijderen van de registratie.',
    registrationDeleteWaringTitle: 'Verwijder registratie',
    registrationDeleteWaringText: 'Bent u zeker dat u deze registratie wenst te verwijderen uit het hulpdossier?',
    goToDetailSupportCase: 'Dossier details',
    unknownSupportCaseWarning: 'Dit dossier behoort niet tot jouw organisatie.',
    nationalSecurityNumberError: 'Ongeldig rijksregisternummer',
};
