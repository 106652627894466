import { CheckCircleOutlined, HighlightOff, Info } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormGrid, IIntake, LabelValue } from '../../../shared';
import { useIntakeIncomeResult } from '../../mappers';

type Props = {
    intake?: IIntake;
};

export const IntakeSummary: FC<Props> = ({ intake }) => {
    const { t } = useTranslation();
    const { total, incomeLimits } = useIntakeIncomeResult(intake);
    return (
        <>
            <Typography variant="subtitle2" sx={{ mb: 2 }}>
                {t('financialResearch')}
            </Typography>

            <FormGrid xs={6} sm={4} md={2}>
                <Stack direction="row" alignItems="center" spacing={2}>
                    {intake?.externalIntake ? (
                        <>
                            <CheckCircleOutlined color="success" />
                            <LabelValue label={t('externalIntakeResult')} value={t('externalIntake.true')} />
                        </>
                    ) : (
                        <>
                            <Info color="primary" />
                            <LabelValue label={t('financialResult')} value={<>€{total}</>} />
                        </>
                    )}
                </Stack>
                {incomeLimits?.map((limit) => (
                    <Stack direction="row" alignItems="center" spacing={2} key={limit.supportTypeId}>
                        {limit.enabled ? <CheckCircleOutlined color="success" /> : <HighlightOff color="error" />}
                        <LabelValue
                            label={limit.supportTypeName}
                            value={
                                <>
                                    {t('limit')}: €{limit.amount}
                                </>
                            }
                        />
                    </Stack>
                ))}
            </FormGrid>
        </>
    );
};
