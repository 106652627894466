import {
    IOrganisation,
    ISaveSupportTypeFrequencies,
    ISaveSupportTypeFrequency,
    ISupportTypeFrequenciesForm,
    ISupportTypeFrequency,
} from '../../shared';

export const supportTypeFrequencyToFormMapper = (
    organisation: IOrganisation,
    frequencies?: ISupportTypeFrequency[],
): ISupportTypeFrequenciesForm => {
    return {
        frequencies: organisation.supportTypes?.map((supportType) => ({
            supportType,
            frequency: frequencies?.find((frequency) => frequency.supportType.id === supportType.id)?.frequency || '',
        })),
    };
};

export const supportTypeFrequencyFromFormMapper = (
    organisation: IOrganisation,
    supportTypeFrequency: ISupportTypeFrequenciesForm,
): ISaveSupportTypeFrequencies => {
    return {
        ...supportTypeFrequency,
        organisationId: organisation.id,
        frequencies: supportTypeFrequency.frequencies
            .map((item) => {
                if (item.supportType.id && item.frequency) {
                    return {
                        supportTypeId: item.supportType.id,
                        frequency: item.frequency,
                    };
                }
            })
            .filter((item) => !!item) as unknown as ISaveSupportTypeFrequency[],
    };
};
