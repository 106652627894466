import { format, isValid } from 'date-fns';

const dateFormat = 'yyyy-MM-dd';

export function mapDateToApiDate(date: Date | undefined | null): string | null {
    return date && isValid(date) && !format(date, dateFormat).startsWith('0') ? format(date, dateFormat) : null;
}

export function mapRequiredDateString(date: Date | undefined): string {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return format(date!, dateFormat);
}
