import { useTranslation } from 'react-i18next';
import { Checkbox, FormControlLabel, Grid, IconButton, MenuItem } from '@mui/material';
import { ContactType, InputTextField, SelectComponent } from '../../../shared';
import { Control, Controller } from 'react-hook-form';
import { Close } from '@mui/icons-material';
import { ChangeEvent } from 'react';

export function ContactInputs({
    field,
    control,
    onRemoveContact,
    handlePrimaryContactChange,
}: {
    field?: string;
    control: Control<any>;
    onRemoveContact: () => void;
    handlePrimaryContactChange: (event: ChangeEvent, onChange: any) => void;
}) {
    const { t } = useTranslation();
    const prefix = field ? `${field}.` : '';

    return (
        <Grid container direction="row" alignItems="center" spacing={2}>
            <Grid item>
                <SelectComponent name={`${prefix}type`} label={t('typeLabel')} control={control}>
                    {Object.entries(ContactType).map(([key, value]) => (
                        <MenuItem value={value} key={value}>
                            {t(key)}
                        </MenuItem>
                    ))}
                </SelectComponent>
            </Grid>
            <Grid item>
                <InputTextField
                    name={`${prefix}data`}
                    label={`${t('contactLabel')}`}
                    control={control}
                    sx={{ minWidth: 350 }}
                    required={true}
                />
            </Grid>
            <Grid item>
                <FormControlLabel
                    control={
                        <Controller
                            defaultValue={false}
                            name={`${prefix}primaryContact`}
                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <Checkbox
                                        checked={value}
                                        onChange={(event) => handlePrimaryContactChange(event, onChange)}
                                        data-testid={`${prefix}primaryContact`}
                                    />
                                );
                            }}
                        />
                    }
                    label={`${t('primaryContact')}`}
                />
            </Grid>
            <Grid item>
                <IconButton aria-label="delete" onClick={onRemoveContact} size="large">
                    <Close />
                </IconButton>
            </Grid>
        </Grid>
    );
}
