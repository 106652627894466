import { CalendarTodayTwoTone, DeleteOutline } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { Button, IconButton } from '@mui/material';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    ConfirmModal,
    IUserRoles,
    NoRowsOverlay,
    Role,
    useGetUserRoles,
    useOrganisationRecords,
    Widget,
} from '../../../shared';
import { useDeleteUserRoles } from '../../hooks/user/delete-user-roles.hook';
import { UserRolesModal } from '../user-roles-modal/user-roles-modal.component';

type Props = {
    userId: string;
};

export const UserRoles: FC<Props> = ({ userId }) => {
    const { t } = useTranslation();
    const [saveUserRolesModal, setSaveUserRolesModal] = useState<{ show: boolean; role?: IUserRoles }>({
        show: false,
        role: undefined,
    });

    const { data: userRoles, isPending } = useGetUserRoles(userId, {});
    const { mutateAsync: deleteUserRoles } = useDeleteUserRoles();
    const organisations = useOrganisationRecords();

    const columns: GridColDef[] = [
        {
            field: 'organisation',
            headerName: t('organisation'),
            flex: 1,
            sortable: false,
            renderCell: (params) => <>{organisations[params.row.context?.key]?.name}</>,
        },
        {
            field: 'roles',
            headerName: t('roles'),
            flex: 1,
            sortable: false,
            valueFormatter: ({ value }) => (value as Role[])?.map(({ key }) => key).join(', '),
        },
        {
            field: ' ',
            headerName: '',
            width: 120,
            align: 'right',
            sortable: false,
            renderCell: (params: GridCellParams) => (
                <>
                    <IconButton
                        size="large"
                        onClick={() => setSaveUserRolesModal({ show: true, role: params.row })}
                        data-testid={`edit-${params.row.id}`}
                    >
                        <EditIcon />
                    </IconButton>
                    <ConfirmModal
                        onConfirm={() => deleteUserRoles(params.row.id)}
                        button={
                            <IconButton aria-label="delete" size="large" data-testid={`delete-${params.id}`}>
                                <DeleteOutline />
                            </IconButton>
                        }
                        title={t('roleDeleteWarningTitle')}
                        text={t('roleDeleteWarningText')}
                    />
                </>
            ),
        },
    ];

    return (
        <>
            <Widget
                icon={<CalendarTodayTwoTone color="primary" />}
                title={t('roles')}
                hasTable
                sx={{ minWidth: '100%' }}
                actions={
                    <Button
                        variant="outlined"
                        color="primary"
                        data-testid="add-user-roles"
                        onClick={() => setSaveUserRolesModal({ show: true, role: undefined })}
                    >
                        + {t('addUserRoles')}
                    </Button>
                }
            >
                <DataGrid
                    autoHeight
                    pagination
                    sortingOrder={['asc', 'desc']}
                    disableColumnFilter
                    disableColumnMenu
                    rows={userRoles?.items || []}
                    columns={columns}
                    rowCount={userRoles?.meta?.totalItems || 0}
                    loading={isPending}
                    pageSizeOptions={[5, 10, 20]}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 5,
                            },
                        },
                    }}
                    slots={{ noRowsOverlay: NoRowsOverlay }}
                />
            </Widget>

            <UserRolesModal
                userId={userId}
                allUserRoles={userRoles?.items || []}
                open={saveUserRolesModal.show}
                handleClose={() => setSaveUserRolesModal({ show: false, role: undefined })}
                userRolesOrganisation={saveUserRolesModal.role}
            />
        </>
    );
};
