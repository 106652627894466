import { Inventory } from '@mui/icons-material';
import { Button } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
    formatDateTimeString,
    IntakeStatus,
    IParams,
    Page,
    PermissionKeys,
    SortOrder,
    Table,
    useHasPermission,
    useQueryParams,
    useSelectedOrganisation,
    Widget,
} from '../../../shared';
import { DuplicatesWarning, SupportCaseTitle } from '../../components';
import { useGetIntakes, useGetSupportCase } from '../../hooks';

export const SupportCaseIntakesPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams<keyof IParams>() as IParams;
    const { organisation } = useSelectedOrganisation();
    const { hasPermission } = useHasPermission();
    const hasWritePermission = hasPermission(PermissionKeys.INTAKES_WRITE);
    const { page, pageSize, setPage, setPageSize, sort, setSort } = useQueryParams({
        defaultSort: [{ field: 'createdDate', sort: 'desc' }],
    });

    const { data: supportCase } = useGetSupportCase(id);
    const { data: intakes, isPending } = useGetIntakes(id, {
        organisationId: organisation?.id,
        page,
        pageSize,
        sortBy: sort[0].field,
        sortOrder: sort[0].sort?.toUpperCase() as SortOrder,
    });

    const columns: GridColDef[] = [
        {
            field: 'createdDate',
            headerName: t('createdDate'),
            minWidth: 100,
            flex: 1,
            valueFormatter: ({ value }) => formatDateTimeString(value),
        },
        {
            field: 'externalIntake',
            headerName: t('externalIntakeResult'),
            minWidth: 100,
            flex: 1,
            valueFormatter: ({ value }) => t(value ? 'externalIntake.true' : 'externalIntake.false'),
            sortable: false,
        },
        {
            field: 'supportPeriod',
            headerName: t('referralOrganisation'),
            minWidth: 100,
            flex: 1,
            sortable: false,
            valueFormatter: ({ value }) => value?.referralOrganisation?.name || '',
        },
        {
            field: 'comment',
            headerName: t('comments'),
            minWidth: 200,
            flex: 2,
            sortable: false,
        },
        {
            field: 'supportPeriodId',
            headerName: t('supportPeriod'),
            minWidth: 100,
            flex: 1,
            sortable: false,
            renderCell: ({ row }) => row.status === IntakeStatus.COMPLETE && (row.supportPeriodId ? t('yes') : t('no')),
        },
        {
            field: 'status',
            headerName: t('status'),
            minWidth: 100,
            flex: 1,
            sortable: false,
            valueFormatter: ({ value }) => t(value),
        },
    ];

    return (
        <Page onBack={() => navigate(-1)} title={supportCase && <SupportCaseTitle supportCase={supportCase} />}>
            <DuplicatesWarning supportCaseId={id} />
            <Widget
                icon={<Inventory color="primary" />}
                title={t('intakes')}
                hasTable
                actions={
                    hasWritePermission && (
                        <Button variant="outlined" color="primary" onClick={() => navigate('new/intro')}>
                            + {t('addIntake')}
                        </Button>
                    )
                }
            >
                <Table
                    rows={intakes?.data || []}
                    columns={columns}
                    rowCount={intakes?.pagination.size || 0}
                    page={page}
                    pageSize={pageSize}
                    setPage={setPage}
                    setPageSize={setPageSize}
                    loading={isPending}
                    sortModel={sort}
                    onSortChange={setSort}
                    onRowClick={({ row }) => row.status === IntakeStatus.DRAFT && navigate(`${row.id}/intro`)}
                />
            </Widget>
        </Page>
    );
};
