import { Edit, NoMeals } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ISupportCase, NoRowsOverlay, PermissionKeys, useHasPermission, Widget } from '../../../shared';
import { UpdateFoodRestrictionsModal } from '../update-food-restrictions-modal/update-food-restrictions-modal.component';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { FullName } from '../full-name/full-name.component';
import { useSupportCaseFoodRestrictionsMapper } from '../../mappers';

interface Props {
    supportCase: ISupportCase;
}

export const SupportCaseFoodRestrictions: FC<Props> = ({ supportCase }) => {
    const { t } = useTranslation();
    const { hasPermission } = useHasPermission();
    const hasFoodRestrictionPermissions = hasPermission([
        PermissionKeys.SUPPORT_CASES_WRITE,
        PermissionKeys.FOOD_RESTRICTIONS_READ,
    ]);

    const [foodRestrictionModal, setFoodRestrictionModal] = useState(false);
    const mappedRestrictions = useSupportCaseFoodRestrictionsMapper(supportCase);

    const columns: GridColDef[] = [
        {
            field: 'supportee',
            headerName: t('supportee'),
            minWidth: 100,
            flex: 1,
            sortable: false,
            renderCell: ({ row }) => (row.supportee ? <FullName data={row.supportee} /> : <>{t('all')}</>),
        },
        { field: 'foodRestriction', headerName: t('foodRestriction'), minWidth: 100, flex: 1, sortable: false },
    ];

    return (
        <>
            <Widget
                icon={<NoMeals color="primary" />}
                title={t('foodRestrictions')}
                actions={
                    hasFoodRestrictionPermissions && (
                        <IconButton onClick={() => setFoodRestrictionModal(true)}>
                            <Edit />
                        </IconButton>
                    )
                }
                hasTable
            >
                {mappedRestrictions?.length === 0 ? (
                    <Box p={2}> {t('emptyStateFoodRestrictions')}</Box>
                ) : (
                    <DataGrid
                        rows={mappedRestrictions || []}
                        columns={columns}
                        disableColumnFilter
                        disableColumnMenu
                        columnHeaderHeight={40}
                        rowHeight={45}
                        hideFooter
                        slots={{ noRowsOverlay: NoRowsOverlay }}
                    />
                )}
            </Widget>

            <UpdateFoodRestrictionsModal
                open={foodRestrictionModal}
                supportCase={supportCase}
                handleClose={() => setFoodRestrictionModal(false)}
            />
        </>
    );
};
