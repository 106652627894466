import { Search } from '@mui/icons-material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Button, Checkbox, FormControlLabel, IconButton, InputAdornment, TextField } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import {
    ConfirmModal,
    IOrganisation,
    Page,
    PermissionKeys,
    SortOrder,
    SupportCaseSupportees,
    Table,
    useHasPermission,
    useQueryParamsContext,
    useSelectedOrganisation,
} from '../../../shared';
import { useDeleteSupportCase, useGetSupportCases } from '../../hooks';

export function SupportCasesPage() {
    const { hasPermission } = useHasPermission();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { organisation } = useSelectedOrganisation();
    const [onlyMySupportCases, setOnlyMySupportCases] = useState(false);
    const {
        queryParams: { page, pageSize, sort, search },
        debouncedQueryParams,
        setPage,
        setPageSize,
        setSort,
        setSearch,
    } = useQueryParamsContext('support-cases', {
        sort: [{ field: 'lastName, firstName', sort: 'asc' }],
    });

    const { isPending, data } = useGetSupportCases(
        {
            page,
            pageSize,
            sortBy: sort[0].field.split(', '),
            sortOrder: sort[0].sort?.toUpperCase() as SortOrder,
            search: debouncedQueryParams.search,
        },
        window.carity.environment.shareSupportCases || !!organisation?.id,
        !(window.carity.environment.shareSupportCases && !onlyMySupportCases) ? organisation?.id : undefined,
    );

    const { mutate: deleteSupportCase } = useDeleteSupportCase();

    const columns: GridColDef[] = [
        {
            field: 'caseNumber',
            headerName: t('caseNumberLabel'),
            minWidth: 160,
            flex: 0.5,
        },
        {
            field: 'cards',
            headerName: t('cardCodeLabel'),
            minWidth: 100,
            flex: 0.5,
            sortable: false,
            renderCell: (params: GridCellParams) => (
                <>{params.row.cards?.length > 1 ? t('multipleCards') : params.row.cards[0]?.code}</>
            ),
        },
        {
            field: 'lastName, firstName',
            headerName: t('nameLabel'),
            minWidth: 200,
            flex: 1,
            renderCell: (params: GridCellParams) => <SupportCaseSupportees supportCase={params.row} />,
        },
        {
            field: 'size',
            headerName: t('numberOfSupportees'),
            sortable: false,
            minWidth: 50,
            flex: 0.5,
            renderCell: (params: GridCellParams) => <>{params.row.supportees?.length}</>,
        },
        ...(window.carity.environment.shareSupportCases
            ? [
                  {
                      field: 'organisations',
                      headerName: t('organisation'),
                      sortable: false,
                      minWidth: 100,
                      flex: 1,
                      valueFormatter: ({ value }: { value: IOrganisation[] }) =>
                          value.map((org) => org.name).join(', '),
                  },
              ]
            : []),
        ...(hasPermission(PermissionKeys.SUPPORT_CASES_WRITE)
            ? ([
                  {
                      field: ' ',
                      width: 120,
                      align: 'right',
                      sortable: false,
                      renderCell: (params: GridCellParams) => (
                          <>
                              <ConfirmModal
                                  onConfirm={() => deleteSupportCase(params.row.id)}
                                  button={
                                      <IconButton aria-label="delete" size="large">
                                          <DeleteOutlineIcon />
                                      </IconButton>
                                  }
                                  title={t('supportCaseDeleteWarningTitle')}
                                  text={t('supportCaseDeleteWarningText')}
                              />
                          </>
                      ),
                  },
              ] as GridColDef[])
            : []),
    ];

    return (
        <Page
            title={t('supportCases')}
            actions={[
                ...(window.carity.environment.shareSupportCases
                    ? [
                          <FormControlLabel
                              control={
                                  <Checkbox
                                      checked={onlyMySupportCases}
                                      onChange={() => setOnlyMySupportCases(!onlyMySupportCases)}
                                  />
                              }
                              label={t('showOnlyMySupportCases')}
                          />,
                      ]
                    : []),
                <TextField
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    label={t('search')}
                    size="small"
                    data-testid="search-input"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                />,
                ...(hasPermission(PermissionKeys.SUPPORT_CASES_WRITE)
                    ? [
                          <Button
                              color="primary"
                              variant="contained"
                              data-testid="newSupportCase"
                              component={Link}
                              to="/support/support-cases/new"
                          >
                              {t('newSupportCase')}
                          </Button>,
                      ]
                    : []),
            ]}
        >
            <Table
                rows={data ? data.data : []}
                columns={columns}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                rowCount={data?.pagination ? data.pagination.size : 0}
                loading={isPending}
                sortModel={sort}
                onSortChange={setSort}
                onRowClick={(params) => navigate(`/support/support-cases/${params.row.id}/detail`)}
            />
        </Page>
    );
}
