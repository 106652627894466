import * as languages from '@cospired/i18n-iso-languages';
import { DeleteOutline, Edit, GroupTwoTone } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import * as countries from 'i18n-iso-countries';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    ISupportCase,
    ISupportee,
    PermissionKeys,
    ConfirmModal,
    useHasPermission,
    Widget,
    NoRowsOverlay,
} from '../../../shared';
import { Gender, SupporteeRelation } from '../../enums';
import { useDeleteSupportee } from '../../hooks';
import { SaveSupporteeModal } from '../save-supportee-modal/save-supportee-modal.component';
import { FullName } from '../full-name/full-name.component';

interface Props {
    supportCase: ISupportCase;
}

export const Supportees: FC<Props> = ({ supportCase }) => {
    const { hasPermission } = useHasPermission();
    const hasWritePermission = hasPermission(PermissionKeys.SUPPORT_CASES_WRITE);
    const { t, i18n } = useTranslation();
    const { mutateAsync: deleteSupportee } = useDeleteSupportee(supportCase.id);
    const [supporteeModal, setSupporteeModal] = useState<{ show: boolean; supportee: ISupportee | null }>({
        show: false,
        supportee: null,
    });

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: t('nameLabel'),
            minWidth: 180,
            flex: 1,
            renderCell: (params: GridCellParams<ISupportee>) => <FullName data={params.row} />,
            sortable: false,
        },
        { field: 'dateOfBirth', headerName: t('dateOfBirthLabel'), flex: 1, minWidth: 100, sortable: false },
        {
            field: 'relation',
            headerName: t('relation'),
            flex: 1,
            minWidth: 100,
            sortable: false,
            renderCell: (params: GridCellParams<ISupportee>) => <RelationCell supportee={params.row} />,
        },
        {
            field: 'nationalSecurityNumber',
            headerName: t('nationalSecurityNumberLabel'),
            flex: 1,
            minWidth: 100,
            sortable: false,
        },
        {
            field: 'gender',
            headerName: t('genderLabel'),
            flex: 1,
            minWidth: 100,
            sortable: false,
            renderCell: (params: GridCellParams<ISupportee>) => <GenderCell supportee={params.row} />,
        },
        {
            field: 'language',
            headerName: t('languageLabel'),
            flex: 1,
            minWidth: 100,
            sortable: false,
            renderCell: (params: GridCellParams) => languages.getName(params.row.language, i18n.language.substr(0, 2)),
        },
        {
            field: 'nationality',
            headerName: t('nationalityLabel'),
            flex: 1,
            minWidth: 100,
            sortable: false,
            renderCell: (params: GridCellParams) =>
                countries.getName(params.row.nationality, i18n.language.substr(0, 2)),
        },
        ...(hasWritePermission
            ? [
                  {
                      field: 'edit',
                      headerName: ' ',
                      sortable: false,
                      width: 110,
                      align: 'right' as const,
                      renderCell: (params: GridCellParams<ISupportee>) => (
                          <ActionsCell
                              onEdit={() => setSupporteeModal({ show: true, supportee: params.row })}
                              supportee={params.row}
                              supportCase={supportCase}
                              onDelete={() => params.row.id && deleteSupportee(params.row.id)}
                          />
                      ),
                  },
              ]
            : []),
    ];

    return (
        <>
            <Widget
                icon={<GroupTwoTone color="primary" />}
                title={t('supportees')}
                actions={
                    <>
                        {hasWritePermission && (
                            <Button
                                variant="outlined"
                                color="primary"
                                data-testid="add-supportee"
                                onClick={() => setSupporteeModal({ show: true, supportee: null })}
                            >
                                + {t('addSupportee')}
                            </Button>
                        )}
                    </>
                }
                hasTable
            >
                <DataGrid
                    rows={supportCase.supportees || []}
                    columns={columns}
                    autoHeight
                    hideFooter
                    disableColumnFilter={true}
                    slots={{ noRowsOverlay: NoRowsOverlay }}
                />
            </Widget>
            <SaveSupporteeModal
                open={supporteeModal.show}
                supportCaseId={supportCase.id}
                supportee={supporteeModal.supportee}
                handleClose={() => setSupporteeModal({ show: false, supportee: null })}
            />
        </>
    );
};

function isValidRelation(relation: string | null | undefined): relation is SupporteeRelation {
    return Object.values(SupporteeRelation).includes(relation as SupporteeRelation);
}

function RelationCell({ supportee }: { supportee: ISupportee }) {
    const { t } = useTranslation();
    return <>{isValidRelation(supportee.relation) ? t(supportee.relation.toLowerCase()) : supportee.relation}</>;
}

function isValidGender(gender: string | null | undefined): gender is Gender {
    return Object.values(Gender).includes(gender as Gender);
}

function GenderCell({ supportee }: { supportee: ISupportee }) {
    const { t } = useTranslation();
    return <>{isValidGender(supportee.gender) ? t(supportee.gender.toLowerCase()) : supportee.gender}</>;
}

function ActionsCell({
    onEdit,
    supportee,
    supportCase,
    onDelete,
}: {
    supportee: ISupportee;
    supportCase: ISupportCase;
    onEdit: () => void;
    onDelete: () => void;
}) {
    const { t } = useTranslation();

    return (
        <>
            <IconButton size="large" onClick={onEdit} data-testid={`edit-${supportee.id}`}>
                <Edit />
            </IconButton>

            {supportee.id !== supportCase.primarySupportee.id && (
                <ConfirmModal
                    onConfirm={onDelete}
                    button={
                        <IconButton aria-label="delete" size="large" data-testid={`delete-${supportee.id}`}>
                            <DeleteOutline />
                        </IconButton>
                    }
                    title={t('supporteeDeleteWarningTitle')}
                    text={t('supporteeDeleteWarningText')}
                />
            )}
        </>
    );
}
