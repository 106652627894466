import { Grid, Stack } from '@mui/material';
import React, { FC } from 'react';
import { DuplicatesWarning } from '../../../support/components';
import {
    InfoActiveSupportPeriods,
    InfoSupportees,
    RegistrationFoodRestrictions,
    RegistrationForm,
    RegistrationWarningPanel,
} from '../../components';
import { ISupportCaseInfo } from '../../models';

type Props = {
    supportCase: ISupportCaseInfo;
};

export const Registration: FC<Props> = ({ supportCase }) => {
    return (
        <>
            <DuplicatesWarning supportCaseId={supportCase.supportCaseId} />
            <Grid container direction="row" justifyContent="center" spacing={2.5}>
                <Grid item sm md={4}>
                    <Stack spacing={3}>
                        <InfoSupportees supportCase={supportCase} />
                        {window.carity.environment.showWarningPanel ? (
                            <RegistrationWarningPanel supportCase={supportCase} />
                        ) : (
                            <InfoActiveSupportPeriods supportPeriods={supportCase.activeSupportPeriods} />
                        )}
                    </Stack>
                </Grid>
                <Grid item md height="fit-content">
                    <Stack spacing={3}>
                        <RegistrationFoodRestrictions supportCase={supportCase} />
                        <RegistrationForm supportCase={supportCase} />
                    </Stack>
                </Grid>
            </Grid>
        </>
    );
};
