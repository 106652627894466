import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, MenuItem, Typography } from '@mui/material';
import * as countries from 'i18n-iso-countries';
import i18n from 'i18next';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    AutocompleteComponent,
    DialogTitleWithClose,
    FormGrid,
    InputTextField,
    IResidenceForm,
    IUpdateResidence,
    SelectComponent,
} from '../../../shared';
import { FamilySituation, LivingSituation } from '../../enums';
import { useUpdateAddress } from '../../hooks';
import { ObjectToArrayMapper, residenceFromFormMapper, residenceToFormMapper } from '../../mappers';
import { useResidenceSchema } from '../../validators';

interface Props {
    open: boolean;
    supportCaseId: string;
    address: IUpdateResidence;
    handleClose: () => void;
}

export const UpdateResidenceModal: FC<Props> = ({ address, handleClose, open, supportCaseId }) => {
    const { t } = useTranslation();

    const { mutateAsync: updateAddress } = useUpdateAddress(supportCaseId);

    const { control, handleSubmit, reset } = useForm<IResidenceForm>({
        resolver: yupResolver(useResidenceSchema()),
        mode: 'all',
    });

    const onSubmit = async (data: IResidenceForm) => {
        await updateAddress(residenceFromFormMapper(data));
        handleClose();
    };

    useEffect(() => {
        if (address) {
            reset({ ...residenceToFormMapper(address, i18n.language.slice(0, 2)) });
        }
    }, [address, open]);

    return (
        <>
            <Dialog open={open}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogTitleWithClose onClose={handleClose}>{t('editResidence')}</DialogTitleWithClose>
                    <DialogContent>
                        <FormGrid xs={12} width="100%" containerProps={{ pl: 2 }} sx={{ '&.MuiGrid-root': { pr: 0 } }}>
                            <Typography variant="subtitle2">{t('familySituation')}</Typography>
                            <SelectComponent
                                name={`familySituation`}
                                label={t('familySituation')}
                                control={control}
                                minWidth={0}
                            >
                                <MenuItem value={''}>{t('unknown')}</MenuItem>
                                {Object.values(FamilySituation).map((situation) => (
                                    <MenuItem value={situation} key={situation}>
                                        {t(situation)}
                                    </MenuItem>
                                ))}
                            </SelectComponent>

                            <Typography variant="subtitle2">{t('livingSituation')}</Typography>
                            <SelectComponent
                                name={`livingSituation`}
                                label={t('livingSituation')}
                                control={control}
                                minWidth={0}
                            >
                                <MenuItem value={''}>{t('unknown')}</MenuItem>
                                {Object.values(LivingSituation).map((situation) => (
                                    <MenuItem value={situation} key={situation}>
                                        {t(situation)}
                                    </MenuItem>
                                ))}
                            </SelectComponent>

                            <Typography variant="subtitle2">{t('addressLabel')}</Typography>
                            <InputTextField name="street" label={t('streetLabel')} control={control} />
                            <InputTextField name="number" label={t('numberLabel')} control={control} />
                            <InputTextField name="box" label={t('boxLabel')} control={control} />
                            <InputTextField name="postalCode" label={t('postalCodeLabel')} control={control} />
                            <InputTextField name="city" label={t('cityLabel')} control={control} />
                            <AutocompleteComponent
                                minWidth={0}
                                name="country"
                                label={t('countryLabel')}
                                control={control}
                                defaultValue={null}
                                options={ObjectToArrayMapper(countries.getNames(i18n.language.slice(0, 2)))}
                                getOptionLabel={(option) => option.name}
                                isOptionEqualToValue={(option, value) =>
                                    value ? option.code === value.code || option.name == value.name : false
                                }
                            />
                        </FormGrid>
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit" variant="contained" color="primary">
                            {t('save')}
                        </Button>
                        <Button onClick={handleClose} color="secondary">
                            {t('cancel')}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};
