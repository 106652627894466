import { DataGrid, GridFeatureMode, GridSortItem, GridSortModel } from '@mui/x-data-grid';
import { ComponentProps } from 'react';
import { NoRowsOverlay } from '../no-rows-overlay/no-rows-overlay.component';

interface Props extends Omit<ComponentProps<typeof DataGrid>, 'pagination'> {
    sortModel?: GridSortItem[];
    onSortChange?: (sort: GridSortModel) => void;
    page?: number;
    setPage?: (page: number) => void;
    pageSize?: number;
    setPageSize?: (pageSize: number) => void;
    pagination?: boolean;
}

export function Table({
    onSortChange,
    page,
    setPage,
    pageSize = 5,
    setPageSize,
    pagination,
    paginationMode = 'server',
    sortingMode = 'server',
    ...props
}: Props) {
    const paginationOptions: Partial<ComponentProps<typeof DataGrid>> =
        pagination === false
            ? {
                  hideFooter: true,
              }
            : paginationMode === 'server'
              ? {
                    pagination: true as const,
                    paginationMode: 'server' as GridFeatureMode,

                    pageSizeOptions: props.pageSizeOptions ? props.pageSizeOptions : [5, 10, 20],
                    paginationModel: { page: page ? page - 1 : 0, pageSize: pageSize },
                    onPaginationModelChange: (value) => {
                        setPage?.(value?.page + 1);
                        setPageSize?.(value?.pageSize || 5);
                    },
                }
              : { pagination: true as const, paginationMode: 'client' as GridFeatureMode };

    return (
        <DataGrid
            autoHeight
            sortingMode={sortingMode}
            sortingOrder={['asc', 'desc']}
            onSortModelChange={onSortChange}
            disableColumnFilter
            disableColumnMenu
            slots={{ noRowsOverlay: NoRowsOverlay }}
            {...paginationOptions}
            {...props}
        />
    );
}
