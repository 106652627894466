import { QrCodeTwoTone, AssessmentTwoTone, TuneTwoTone, ContactsTwoTone } from '@mui/icons-material';
import React, { FC, useMemo } from 'react';
import { BottomNavigation, BottomNavigationAction, Box, Tab, Tabs } from '@mui/material';
import { PermissionKeys, useHasPermission, useBreakpoint, SelectOrganisation } from '../../../shared';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Account } from '../account/account.component';
import { AppMenu } from '../app-menu/app-menu.component';

export const Navigation: FC = () => {
    const { t } = useTranslation();
    const { hasPermission, isLoading } = useHasPermission();
    const location = useLocation();
    const selectedTab = useMemo(() => location.pathname.split('/')[1] || 'registration', [location]);
    const breakpointMd = useBreakpoint('md');
    const navItems = [
        {
            key: 'registration',
            label: t('registration'),
            hasPermission: hasPermission(PermissionKeys.REGISTRATIONS_WRITE),
            icon: <QrCodeTwoTone />,
        },
        {
            key: 'support',
            label: t('cases'),
            hasPermission: hasPermission(PermissionKeys.SUPPORT_CASES_READ),
            icon: <ContactsTwoTone />,
        },
        {
            key: 'registrations',
            label: t('registrations'),
            hasPermission: hasPermission(PermissionKeys.REGISTRATIONS_READ),
            icon: <AssessmentTwoTone />,
        },
        {
            key: 'reports',
            label: t('reports'),
            hasPermission: hasPermission(PermissionKeys.REGISTRATIONS_READ),
            icon: <AssessmentTwoTone />,
        },
        {
            key: 'admin',
            to: hasPermission(PermissionKeys.ATTRIBUTES_WRITE) ? 'admin' : 'admin/card-sets',
            label: t('admin'),
            hasPermission:
                hasPermission(PermissionKeys.ATTRIBUTES_WRITE) ||
                hasPermission(PermissionKeys.ORGANISATIONS_READ, true),
            icon: <TuneTwoTone />,
        },
    ];

    return !isLoading ? (
        breakpointMd ? (
            <>
                <Tabs indicatorColor="primary" textColor="primary" value={selectedTab} sx={{ flexGrow: 1 }}>
                    {navItems.map((tab) =>
                        tab.hasPermission ? (
                            <Tab
                                key={tab.key}
                                sx={{ minHeight: 55 }}
                                label={tab.label}
                                value={tab.key}
                                component={Link}
                                to={tab.to || `/${tab.key}`}
                            />
                        ) : null,
                    )}
                </Tabs>
                <SelectOrganisation />
                <Account />
                <AppMenu />
            </>
        ) : (
            <>
                <Box sx={{ flexGrow: 1 }} />
                <SelectOrganisation />
                <Account />
                <AppMenu />
                <BottomNavigation showLabels value={selectedTab}>
                    {navItems?.map(
                        ({ label, key, icon, hasPermission }) =>
                            hasPermission && (
                                <BottomNavigationAction
                                    key={key}
                                    label={label}
                                    value={key}
                                    component={Link}
                                    to={`/${key}`}
                                    icon={icon}
                                />
                            ),
                    )}
                </BottomNavigation>
            </>
        )
    ) : null;
};
