import { useTranslation } from 'react-i18next';
import { Button, IconButton, MenuItem, Stack } from '@mui/material';
import { ISupportCaseFoodRestriction, ISupportee, SelectComponent } from '../../../shared';
import { Delete } from '@mui/icons-material';
import { useFoodRestrictionsList } from '../../../admin/hooks';
import React, { FC } from 'react';
import { FullName } from '../full-name/full-name.component';
import { useFieldArray, UseFormReturn } from 'react-hook-form';

interface Props {
    supportees: ISupportee[];
    form: UseFormReturn<{ foodRestrictions: ISupportCaseFoodRestriction[] }>;
}

export const SupportCaseFoodRestrictionInputs: FC<Props> = ({ supportees, form }) => {
    const { t } = useTranslation();

    const { data: foodRestrictionList } = useFoodRestrictionsList({ pageSize: 100 });

    const { fields, append, remove } = useFieldArray({
        control: form.control,
        name: 'foodRestrictions',
    });

    return (
        <Stack spacing={2}>
            {fields.map((field, index) => (
                <Stack direction="row" spacing={2} key={field.id} sx={{ pt: 2 }}>
                    <SelectComponent
                        name={`foodRestrictions.[${index}].supportee`}
                        defaultValue={'all'}
                        label={t('supportee')}
                        control={form.control}
                        required
                    >
                        <MenuItem value={'all'} key={'all'}>{`${t('all')}`}</MenuItem>
                        {supportees?.map((item) => (
                            <MenuItem value={item.id} key={item.id}>
                                <FullName data={item} />
                            </MenuItem>
                        ))}
                    </SelectComponent>

                    <SelectComponent
                        name={`foodRestrictions.[${index}].foodRestriction`}
                        label={t('foodRestriction')}
                        defaultValue={''}
                        control={form.control}
                        required
                    >
                        {foodRestrictionList?.data.map((item) => (
                            <MenuItem value={item.id} key={item.id}>
                                {`${item.name}`}
                            </MenuItem>
                        ))}
                    </SelectComponent>

                    <IconButton onClick={() => remove(index)}>
                        <Delete />
                    </IconButton>
                </Stack>
            ))}
            <Button
                onClick={() => append({ supportee: 'all', foodRestriction: '' })}
                color="secondary"
                sx={{ justifyContent: 'left' }}
            >
                + {t('foodRestrictionAdd')}
            </Button>
        </Stack>
    );
};
