import { DeleteOutline, Edit, Search } from '@mui/icons-material';
import { Button, IconButton, InputAdornment, TextField } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { ChangeEvent, FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ConfirmModal, Page, SortOrder, Table, useDebouncedState, useQueryParamsContext } from '../../../shared';
import { useDeleteFoodRestriction, useFoodRestrictionsList } from '../../hooks';

export const FoodRestrictionsPage: FC = () => {
    const { t } = useTranslation();
    const {
        queryParams: { page, pageSize, sort },
        setPage,
        setPageSize,
        setSort,
    } = useQueryParamsContext('food-restrictions', {
        sort: [{ field: 'name', sort: 'asc' }],
    });

    const [debouncedSearch, search, setSearch] = useDebouncedState('');
    const onChangeSearch = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => setSearch(target.value), []);

    const { isPending, data } = useFoodRestrictionsList({
        page,
        pageSize,
        sortBy: sort[0].field,
        sortOrder: sort[0].sort?.toUpperCase() as SortOrder,
        search: debouncedSearch,
    });

    const { mutate: mutateDelete } = useDeleteFoodRestriction();

    const columns: GridColDef[] = [
        { field: 'name', headerName: t('nameLabel'), minWidth: 150, flex: 1 },
        {
            field: ' ',
            headerName: '',
            width: 120,
            align: 'right',
            sortable: false,
            renderCell: (params: GridCellParams) => (
                <>
                    <IconButton component={Link} to={`/admin/food-restrictions/${params.row.id}/edit`}>
                        <Edit />
                    </IconButton>
                    <ConfirmModal
                        onConfirm={() => {
                            mutateDelete(params.row.id);
                        }}
                        button={
                            <IconButton aria-label="delete">
                                <DeleteOutline />
                            </IconButton>
                        }
                        title={t('foodRestrictionDeleteWarningTitle')}
                        text={t('foodRestrictionDeleteWarningText')}
                    />
                </>
            ),
        },
    ];

    return (
        <Page
            title={t('foodRestrictions')}
            actions={[
                <TextField
                    value={search}
                    onChange={onChangeSearch}
                    label={t('search')}
                    size="small"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                />,
                <Button component={Link} to="/admin/food-restrictions/new" color="primary" variant="contained">
                    {t('newFoodRestriction')}
                </Button>,
            ]}
        >
            <Table
                rows={data?.data || []}
                columns={columns}
                rowCount={data?.pagination.size || 0}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                loading={isPending}
                sortModel={sort}
                onSortChange={setSort}
            />
        </Page>
    );
};
