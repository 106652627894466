import { yupResolver } from '@hookform/resolvers/yup';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Button, InputAdornment, Stack, Typography } from '@mui/material';
import { FC, Fragment, useEffect } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    ControlledNumberInput,
    FormCardSectionComponent,
    IIntake,
    IIntakeIncomeForm,
    Widget,
} from '../../../../shared';
import { useUpdateIntake } from '../../../hooks';
import { intakeIncomeToFormMapper } from '../../../mappers';
import { useIntakeIncomeSchema } from '../../../validators';

type Props = {
    intake?: IIntake;
};

export const IntakeIncomePage: FC<Props> = ({ intake }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { mutateAsync: updateIntake, isPending } = useUpdateIntake();

    const form = useForm<IIntakeIncomeForm>({
        resolver: yupResolver(useIntakeIncomeSchema()),
        mode: 'onSubmit',
    });
    const { fields: income } = useFieldArray({ control: form.control, name: 'income' });
    useEffect(() => {
        if (intake) {
            form.reset(intakeIncomeToFormMapper(intake));
        }
    }, [form, intake]);

    const onSubmit = async (item: IIntakeIncomeForm, next = true) => {
        if (intake) {
            await updateIntake({ supportCaseId: intake.supportCaseId, intakeId: intake.id, item });
            navigate(next ? '../expenses' : '../intro');
        }
    };

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit((data) => onSubmit(data, false))} noValidate={true} autoComplete="off">
                <Widget
                    footer={
                        <Stack direction="row" justifyContent="space-between">
                            <Button
                                startIcon={<ChevronLeft />}
                                onClick={form.handleSubmit((data) => onSubmit(data, false))}
                                disabled={isPending}
                            >
                                {t('previous')}
                            </Button>
                            <Button
                                endIcon={<ChevronRight />}
                                variant="contained"
                                color="primary"
                                onClick={form.handleSubmit((data) => onSubmit(data))}
                                disabled={isPending}
                            >
                                {t('next')}
                            </Button>
                        </Stack>
                    }
                >
                    <FormCardSectionComponent title={t('income')}>
                        <Typography sx={{ mb: 2 }}>{t('incomeHelpText')}</Typography>
                        <Stack spacing={2} width="400px">
                            {income.map((singleIncome, index) => (
                                <Fragment key={singleIncome.id}>
                                    <ControlledNumberInput
                                        label={singleIncome.incomeTypeName}
                                        name={`income.${index}.amount`}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Typography>€</Typography>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Fragment>
                            ))}
                        </Stack>
                    </FormCardSectionComponent>
                </Widget>
            </form>
        </FormProvider>
    );
};
