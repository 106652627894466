import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { array, boolean, date, number, object, string } from 'yup';

export function useRegistrationSchema() {
    const { t } = useTranslation();

    return useMemo(
        () =>
            object().shape({
                id: string().optional(),
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                // ts ignore is used for setting the message in nonNullable
                dateOfRegistration: date().typeError(t('dateRequired')).optional().nonNullable(t('dateRequired')),
                comment: string().max(120, t('commentTooLarge')).optional(),
                supportType: object()
                    .shape({
                        id: string().required(),
                        name: string().required(),
                    })
                    .required(t('supportTypeRequired')),
                attributes: array().of(
                    object().shape({
                        configurationId: string().required(),
                        booleanValue: boolean(),
                        amountValue: number().transform((value) => (value ? value : 0)),
                    }),
                ),
            }),
        [t],
    );
}

export function useRegistrationNationSecurityNumberSchema() {
    const { t } = useTranslation();
    return useMemo(
        () =>
            object().shape({
                nationalSecurityNumber: string()
                    .transform((val) => val.replace(/\./g, '').replace(/-/g, '').replace(/_/g, ''))
                    .test('len', t('nationalSecurityNumberError'), (val) => (val ? val.length === 11 : false))
                    .required(),
            }),
        [t],
    );
}

export function useRegistrationQrCodeSchema() {
    const { t } = useTranslation();
    return useMemo(
        () =>
            object().shape({
                code: yup
                    .string()
                    .test('len', t('codeLengthError'), (val) => (val ? val.replace(/_/g, '').length === 9 : false))
                    .required(),
            }),
        [t],
    );
}
