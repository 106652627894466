import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button } from '@mui/material';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { object, string } from 'yup';
import { FormCard, FormCardSectionComponent, InputTextField, IParams, Page } from '../../../shared';
import { useFoodRestriction, useSaveFoodRestriction } from '../../hooks';
import { ISaveFoodRestriction } from '../../models';

export const FoodRestrictionEditPage: FC = () => {
    const { t } = useTranslation();
    const { id } = useParams<keyof IParams>() as IParams;
    const navigate = useNavigate();

    const { data: foodRestriction, isFetching } = useFoodRestriction(id);
    const { mutateAsync: saveFoodRestriction, isPending } = useSaveFoodRestriction();

    const form = useForm<ISaveFoodRestriction>({
        resolver: yupResolver(object().shape({ name: string().required() })),
        mode: 'onSubmit',
    });

    useEffect(() => {
        foodRestriction && form.reset(foodRestriction);
    }, [form, foodRestriction]);

    const onSubmit = useCallback(
        async (item: ISaveFoodRestriction) => {
            await saveFoodRestriction({ id, item });
            navigate('/admin/food-restrictions');
        },
        [saveFoodRestriction, id, navigate],
    );

    const actions = useMemo(
        () => [
            <Button variant="contained" color="primary" onClick={form.handleSubmit(onSubmit)} disabled={isPending}>
                {t('save')}
            </Button>,
            <Button onClick={() => navigate('/admin/food-restrictions')} color="secondary">
                {t('cancel')}
            </Button>,
        ],
        [form, onSubmit, isPending, t, navigate],
    );
    const reversedActions = useMemo(() => [...actions].reverse(), [actions]);

    return (
        <Page
            title={<Box width="300px">{id ? t('editFoodRestriction') : t('newFoodRestriction')}</Box>}
            actions={reversedActions}
            onBack={() => navigate('/admin/food-restrictions')}
            loading={isFetching || isPending}
        >
            <FormCard handleSubmit={form.handleSubmit(onSubmit)} actions={actions}>
                <FormCardSectionComponent title={t('data')}>
                    <InputTextField
                        name="name"
                        label={t('nameLabel')}
                        control={form.control}
                        required
                        sx={{ width: '400px' }}
                    />
                </FormCardSectionComponent>
            </FormCard>
        </Page>
    );
};
