import { ChevronRight } from '@mui/icons-material';
import { Divider, List, ListItem, ListItemText, ListItemButton, Box, Typography, Paper } from '@mui/material';
import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useOrganisations } from '../../../shared';
import { ISupportCaseInfo } from '../../models';

type Props = {
    supportCases: ISupportCaseInfo[];
    setSelectedSupportCase: (supportCase: ISupportCaseInfo) => void;
};

export const SelectSupportCase: FC<Props> = ({ supportCases, setSelectedSupportCase }) => {
    const { t } = useTranslation();
    const organisations = useOrganisations();

    return (
        <Paper
            sx={{ maxWidth: 420, width: '100%', mt: 4, marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}
        >
            <Box sx={{ p: 4 }}>
                <Typography sx={{ mt: 2 }} variant="h5">
                    {t('selectSupportCaseTitle')}
                </Typography>
                <Typography variant="subtitle1" component="div">
                    {t('selectSupportCaseText')}
                </Typography>
            </Box>
            <List>
                {supportCases.map((supportCase) => (
                    <Fragment key={supportCase.supportCaseId}>
                        <Divider component="li" />
                        <ListItem>
                            <ListItemButton onClick={() => setSelectedSupportCase(supportCase)}>
                                <ListItemText
                                    primary={supportCase.caseNumber}
                                    secondary={supportCase.organisations
                                        .map(
                                            (organisation) =>
                                                organisations.find((org) => org.id === organisation.id)?.name,
                                        )
                                        .join(', ')}
                                />
                                <ChevronRight />
                            </ListItemButton>
                        </ListItem>
                    </Fragment>
                ))}
            </List>
        </Paper>
    );
};
