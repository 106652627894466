import { ISupportCase, ISupportCaseFoodRestriction, ISupportCaseFoodRestrictions } from '../../shared';
import { useFoodRestrictionsList } from '../../admin/hooks';
import { ISupportCaseInfo } from '../../registration/models';
import { useMemo } from 'react';

export function supportCaseFoodRestrictionsToFormMapper(supportCase: ISupportCase): {
    foodRestrictions: ISupportCaseFoodRestriction[];
} {
    return {
        foodRestrictions:
            supportCase.foodRestrictions?.map((item) => ({
                supportee: item.supportee || 'all',
                foodRestriction: item.foodRestriction,
            })) || [],
    };
}

export function supportCaseFoodRestrictionsFromFormMapper(data: {
    foodRestrictions: ISupportCaseFoodRestriction[];
}): ISupportCaseFoodRestrictions {
    return {
        foodRestrictions: data.foodRestrictions.map(({ supportee, foodRestriction }) => ({
            supportee: supportee === 'all' ? undefined : supportee,
            foodRestriction: foodRestriction,
        })),
    };
}

export function useSupportCaseFoodRestrictionsMapper(
    supportCase: ISupportCase | ISupportCaseInfo,
): { foodRestriction: any; id: string; supportee: any }[] | undefined {
    const { data: foodRestrictions } = useFoodRestrictionsList({ pageSize: 100 });

    return useMemo(() => {
        return supportCase?.foodRestrictions
            ? supportCase?.foodRestrictions?.map(({ supportee, foodRestriction }) => ({
                  id: `${supportee || ''}${foodRestriction}`,
                  supportee: supportCase.supportees?.find((s) => s.id === supportee),
                  foodRestriction: foodRestrictions?.data.find((x) => x.id === foodRestriction)?.name || '',
              }))
            : [];
    }, [supportCase, foodRestrictions]);
}
