import React, { FC, Fragment, useMemo } from 'react';
import { MenuItem } from '@mui/material';
import { ControlledNumberInput, CreditFrequency, ISupportType, SelectComponent } from '../../../shared';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface Props {
    path: string;
    supportTypes: ISupportType[];
}

export const SupportTypesInfoInputs: FC<Props> = ({ path, supportTypes }) => {
    const { t } = useTranslation();

    const form = useFormContext();
    const watchSupportTypeInfo = form.watch(`${path}.supportTypeId`);

    const hasCredit = useMemo(() => {
        return watchSupportTypeInfo && supportTypes?.find((type) => type.id === watchSupportTypeInfo)?.hasCredit;
    }, [watchSupportTypeInfo, supportTypes]);

    return (
        <Fragment>
            <SelectComponent name={`${path}.supportTypeId`} label={t('nameLabel')} control={form.control} required>
                {supportTypes?.map((item: ISupportType) => (
                    <MenuItem value={item.id} key={item.id}>
                        {item.name}
                    </MenuItem>
                ))}
            </SelectComponent>

            {hasCredit && (
                <>
                    <ControlledNumberInput name={`${path}.credit`} label={t('creditLabel')} />
                    <SelectComponent
                        name={`${path}.frequency`}
                        defaultValue={''}
                        label={t('frequencyLabel')}
                        control={form.control}
                    >
                        {Object.values(CreditFrequency)?.map((item) => (
                            <MenuItem value={item} key={item}>
                                {t(item)}
                            </MenuItem>
                        ))}
                    </SelectComponent>
                </>
            )}
        </Fragment>
    );
};
