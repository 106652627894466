import React, { FC, useMemo } from 'react';
import {
    ControlledNumberInput,
    FormCardSectionComponent,
    FormGrid,
    InputTextField,
    MultipleSelectComponent,
    SelectComponent,
    useSupportTypes,
} from '../../../shared';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { MenuItem } from '@mui/material';
import { useGetOrganisationTypes } from '../../hooks';

export const OrganisationDataInputs: FC = () => {
    const { t } = useTranslation();
    const { control } = useFormContext();

    const { data: organisationTypesData } = useGetOrganisationTypes({
        page: 1,
        pageSize: 100,
        sortBy: 'name',
        sortOrder: 'ASC',
    });

    const supportTypes = useSupportTypes();
    const organisationTypes = useMemo(() => organisationTypesData?.data || [], [organisationTypesData]);

    return (
        <FormCardSectionComponent title={t('data')}>
            <FormGrid xs={3}>
                <InputTextField name="name" label={t('nameLabel')} control={control} required />
                <SelectComponent name={'type'} label={t('typeLabel')} control={control} required>
                    {organisationTypes.map((type) => (
                        <MenuItem key={type.id} value={type.id}>
                            {type.name}
                        </MenuItem>
                    ))}
                </SelectComponent>
                <MultipleSelectComponent
                    name={'supportTypes'}
                    label={t('supportTypes')}
                    control={control}
                    items={supportTypes.map((supportType) => ({
                        id: supportType.id as string,
                        name: supportType.name,
                    }))}
                />
                <ControlledNumberInput
                    name="defaultSupportPeriod"
                    label={t('defaultSupportPeriod')}
                    required
                    sx={{ maxWidth: 200 }}
                />
            </FormGrid>
        </FormCardSectionComponent>
    );
};
