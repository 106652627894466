import { Search } from '@mui/icons-material';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, Grid, InputAdornment, TextField } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
    DialogTitleWithClose,
    IParams,
    ISupportCaseOrganisation,
    SortOrder,
    Table,
    useDebouncedState,
    useGetOrganisations,
    useQueryParams,
} from '../../../shared';
import { useGetSupportCase, useSaveSupportCase } from '../../hooks';

export function EditOrganisationAccessDialog(props: { open: boolean; onClose: () => void }) {
    const { t } = useTranslation();
    const [allowedOrganisations, setAllowedOrganisations] = useState<ISupportCaseOrganisation[]>([]);
    const { id } = useParams<keyof IParams>() as IParams;
    const { data: supportCase, dataUpdatedAt } = useGetSupportCase(id);
    const { page, pageSize, setPage, setPageSize, sort, setSort } = useQueryParams({
        defaultSort: [{ field: 'name', sort: 'asc' }],
    });
    const [debouncedSearch, search, setSearch] = useDebouncedState('');

    // Get a list of all the organisations
    const { isLoading, data: organisations } = useGetOrganisations({
        page,
        pageSize,
        sortBy: sort[0].field,
        sortOrder: sort[0].sort?.toUpperCase() as SortOrder,
        search: debouncedSearch,
    });

    // change the organisations access to the support
    const { mutate } = useSaveSupportCase();

    const columns: GridColDef[] = [
        {
            field: 'allowed_organisation',
            headerName: ' ',
            renderCell: (params: GridCellParams) => (
                <Checkbox checked={allowedOrganisations.some((org) => org.id == params.row.id)} />
            ),
            sortable: false,
            width: 60,
        },
        { field: 'name', headerName: t('nameLabel'), minWidth: 150, flex: 1 },
    ];

    const onChangeSearch = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => setSearch(target.value), []);

    const editOrganisationPermission = (id: string) => {
        if (supportCase && id) {
            if (!allowedOrganisations.find((org) => org.id == id)) {
                setAllowedOrganisations([...allowedOrganisations, { id: id }]);
            } else {
                setAllowedOrganisations(allowedOrganisations.filter((org) => org.id != id));
            }
        }
    };

    const saveOrganisationPermissions = () => {
        if (supportCase) {
            supportCase.organisations = allowedOrganisations;
            mutate(supportCase);
            props.onClose();
        }
    };

    const handleClose = () => {
        props.onClose();
        setAllowedOrganisations(supportCase?.organisations ? supportCase.organisations : []);
    };

    useEffect(() => {
        if (supportCase) {
            setAllowedOrganisations(supportCase.organisations);
        }
    }, [supportCase, dataUpdatedAt]);

    return (
        <>
            <Dialog open={props.open} fullWidth={true} maxWidth="lg" id="edit-permissions-dialog">
                <DialogTitleWithClose onClose={handleClose}>{t('editOrganisationPermissions')}</DialogTitleWithClose>
                <DialogContent>
                    <Grid container direction="column" spacing={3}>
                        <Grid item>
                            <TextField
                                data-testid="search-input"
                                value={search}
                                onChange={onChangeSearch}
                                label={t('search')}
                                size="small"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search />
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{ mt: 1 }}
                            />
                        </Grid>

                        <Grid item>
                            <Table
                                rows={organisations?.data || []}
                                columns={columns}
                                pageSizeOptions={[10]}
                                rowCount={organisations?.pagination.size || 0}
                                page={page}
                                pageSize={pageSize}
                                setPage={setPage}
                                setPageSize={setPageSize}
                                loading={isLoading}
                                sortModel={sort}
                                onSortChange={setSort}
                                onRowClick={({ row }) => editOrganisationPermission(row.id || '')}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={allowedOrganisations.length === 0}
                        onClick={saveOrganisationPermissions}
                        variant="contained"
                        color="primary"
                        data-testid="save"
                    >
                        {t('save')}
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        {t('cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
