import { Alert, AlertTitle, Box, Stack, Step, StepLabel, Stepper } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useLocation, useParams } from 'react-router-dom';
import { Page } from '../../../shared';
import { SupportCaseTitle } from '../../components';
import { useGetIntake, useGetSupportCase } from '../../hooks';
import { IntakeCompletionPage } from './intake-completion/intake-completion.page';
import { IntakeExpensesPage } from './intake-expense/intake-expense.page';
import { IntakeIncomePage } from './intake-income/intake-income.page';
import { IntakeIntroPage } from './intake-intro/intake-intro.page';

type RouteProps = {
    id: string;
    intakeId: string;
};
export const SupportCaseIntakeWizardPage: FC = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const { id: supportCaseId, intakeId } = useParams<keyof RouteProps>();
    const { data: supportCase } = useGetSupportCase(supportCaseId as string);

    const { data: intake } = useGetIntake(supportCaseId, intakeId);

    const steps = ['intro', 'income', 'expenses', 'completion'];
    const activeStep = useMemo(() => {
        const activeStepPath = location.pathname.split('/').pop();
        return Math.max(steps.indexOf(`${activeStepPath}`), 0);
    }, [location]);

    return (
        <Page
            title={
                supportCase && (
                    <Stack direction="row" alignItems="center">
                        {t('newIntake')}: <SupportCaseTitle supportCase={supportCase} />
                    </Stack>
                )
            }
        >
            <Box sx={{ p: 3, pt: 0 }}>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((step) => (
                        <Step key={step}>
                            <StepLabel>{t('intakeStep.' + step)}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>
            {intake?.externalIntakeError && (
                <Alert severity={'error'} sx={{ m: 2 }}>
                    <AlertTitle>{t('externalIntakeError')}</AlertTitle>
                </Alert>
            )}
            <Routes>
                <Route path="/intro" element={<IntakeIntroPage intake={intake} supportCaseId={supportCaseId} />} />
                <Route path="/income" element={<IntakeIncomePage intake={intake} />} />
                <Route path="/expenses" element={<IntakeExpensesPage intake={intake} />} />
                <Route path="/completion" element={<IntakeCompletionPage intake={intake} />} />
            </Routes>
        </Page>
    );
};
