import { Search } from '@mui/icons-material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import { Button, IconButton, InputAdornment, TextField } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
    ConfirmModal,
    Page,
    SortOrder,
    Table,
    useGetCustomAttributeConfigurations,
    useQueryParamsContext,
    useSelectedOrganisation,
} from '../../../shared';
import { useDeleteCustomAttributeConfiguration } from '../../hooks';

export const CustomAttributeConfigurationsPage: FC = () => {
    const { t } = useTranslation();
    const { organisation } = useSelectedOrganisation();
    const {
        queryParams: { page, pageSize, sort, search },
        debouncedQueryParams,
        setPage,
        setPageSize,
        setSort,
        setSearch,
    } = useQueryParamsContext('custom-attribute-configurations', {
        sort: [{ field: 'name', sort: 'asc' }],
    });

    const { isLoading, data } = useGetCustomAttributeConfigurations({
        page,
        pageSize,
        sortBy: sort[0].field,
        sortOrder: sort[0].sort?.toUpperCase() as SortOrder,
        search: debouncedQueryParams.search,
        organisationId: organisation?.id,
    });
    const { mutateAsync: deleteCustomAttributeConfiguration } = useDeleteCustomAttributeConfiguration();

    const columns: GridColDef[] = [
        { field: 'name', headerName: t('nameLabel'), minWidth: 150, flex: 1 },
        { field: 'code', headerName: t('codeLabel'), minWidth: 150, flex: 0.5 },
        {
            field: 'type',
            headerName: t('typeLabel'),
            minWidth: 150,
            flex: 0.5,
            valueFormatter: ({ value }) => t(value),
        },
        {
            field: 'defaultSelected',
            headerName: t('defaultSelectedLabel'),
            flex: 1,
            valueFormatter: ({ value }) => (value ? t('yes') : t('no')),
        },
        {
            field: ' ',
            headerName: '',
            width: 120,
            align: 'right',
            sortable: false,
            renderCell: (params: GridCellParams) => (
                <>
                    <IconButton
                        component={Link}
                        to={`/admin/custom-attribute-configurations/edit/${params.row.id}`}
                        size="large"
                    >
                        <EditIcon />
                    </IconButton>
                    <ConfirmModal
                        onConfirm={() => deleteCustomAttributeConfiguration(params.row.id)}
                        button={
                            <IconButton aria-label="delete" size="large">
                                <DeleteOutlineIcon />
                            </IconButton>
                        }
                        title={t('customAttributeConfigurationDeleteWarningTitle')}
                        text={t('customAttributeConfigurationDeleteWarningText')}
                    />
                </>
            ),
        },
    ];

    return (
        <Page
            title={t('customAttributeConfigurations')}
            actions={[
                <TextField
                    value={search}
                    onChange={({ target }) => setSearch(target.value)}
                    label={t('search')}
                    size="small"
                    data-testid="search-input"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                />,
                <Button
                    component={Link}
                    to="/admin/custom-attribute-configurations/edit"
                    color="primary"
                    variant="contained"
                    data-testid="newCustomAttributeConfiguration"
                >
                    {t('newCustomAttributeConfiguration')}
                </Button>,
            ]}
        >
            <Table
                rows={data?.data || []}
                columns={columns}
                rowCount={data?.pagination.size || 0}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                loading={isLoading || !organisation}
                sortModel={sort}
                onSortChange={setSort}
            />
        </Page>
    );
};
