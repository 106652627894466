import { Search } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, InputAdornment, TextField } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    DialogTitleWithClose,
    SortOrder,
    SupportCaseSupportees,
    Table,
    useQueryParams,
    useSelectedOrganisation,
} from '../../../shared';
import { useGetSupportCases } from '../../../support/hooks';

interface Props {
    open: boolean;
    handleClose: () => void;
}

export const SearchSupportCaseModal: FC<Props> = ({ open, handleClose }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { page, pageSize, setPage, setPageSize, sort, setSort, search, setSearch, debouncedSearch } = useQueryParams({
        defaultSort: [{ field: 'lastName, firstName', sort: 'asc' }],
    });

    const { organisation } = useSelectedOrganisation();
    const { isPending, data } = useGetSupportCases(
        {
            page,
            pageSize,
            sortBy: sort[0].field.split(', '),
            sortOrder: sort[0].sort?.toUpperCase() as SortOrder,
            search: debouncedSearch,
        },
        window.carity.environment.shareSupportCases || !!organisation?.id,
        window.carity.environment.shareSupportCases ? undefined : organisation?.id,
    );

    const columns: GridColDef[] = [
        {
            field: 'caseNumber',
            headerName: t('caseNumberLabel'),
            minWidth: 160,
            flex: 0.5,
        },
        {
            field: 'card',
            headerName: t('cardCodeLabel'),
            minWidth: 100,
            flex: 0.5,
            renderCell: (params: GridCellParams) => <>{params.row.card?.code}</>,
        },
        {
            field: 'lastName, firstName',
            headerName: t('nameLabel'),
            minWidth: 200,
            flex: 1,
            renderCell: (params: GridCellParams) => <SupportCaseSupportees supportCase={params.row} />,
        },
        {
            field: 'size',
            headerName: t('numberOfSupportees'),
            sortable: false,
            minWidth: 100,
            flex: 0.5,
            renderCell: (params: GridCellParams) => <>{params.row.supportees?.length}</>,
        },
    ];

    return (
        <Dialog open={open} fullWidth={true} maxWidth="lg" disableRestoreFocus>
            <DialogTitleWithClose onClose={handleClose}>{t('searchSupportCase')}</DialogTitleWithClose>
            <DialogContent>
                <TextField
                    sx={{ mt: 1, mb: 1 }}
                    value={search}
                    onChange={({ target }) => setSearch(target.value)}
                    label={t('search')}
                    size="small"
                    data-testid="search-input"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                    autoFocus
                />
                <Table
                    rows={data ? data.data : []}
                    columns={columns}
                    rowCount={data?.pagination ? data.pagination.size : 0}
                    page={page}
                    pageSize={pageSize}
                    setPage={setPage}
                    setPageSize={setPageSize}
                    loading={isPending}
                    sortModel={sort}
                    onSortChange={setSort}
                    onRowClick={(params) => navigate(`/registration/info?case=${params.row.id}`)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" variant="contained">
                    {t('cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
