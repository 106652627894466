import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, MenuItem, Stack } from '@mui/material';
import React, { FC, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    DialogTitleWithClose,
    IUserRoles,
    IUserRolesForm,
    MultipleSelectComponent,
    SelectComponent,
    useOrganisations,
} from '../../../shared';
import { useGetRoles, useSaveUserRoles } from '../../hooks';
import { useUserRoleSchema } from '../../validators';

interface Props {
    userId: string;
    allUserRoles: IUserRoles[];
    open: boolean;
    handleClose: () => void;
    userRolesOrganisation?: IUserRoles;
}

export const UserRolesModal: FC<Props> = ({ open, handleClose, userId, allUserRoles, userRolesOrganisation }) => {
    const { t } = useTranslation();

    const organisations = useOrganisations();
    const { data: roles } = useGetRoles({ page: 1, limit: 100 });

    const { mutateAsync: saveUserRoles } = useSaveUserRoles();

    const { control, handleSubmit, reset } = useForm<IUserRolesForm>({
        resolver: yupResolver(useUserRoleSchema()),
        mode: 'all',
    });

    useEffect(() => {
        if (userRolesOrganisation) {
            reset({
                id: userRolesOrganisation.id,
                organisationId: userRolesOrganisation.context?.key,
                roles: userRolesOrganisation.roles.map((role) => role.id),
            });
        } else {
            reset({ roles: [] });
        }
    }, [reset, userRolesOrganisation]);

    const onSubmit = async (data: IUserRolesForm) => {
        handleClose();
        await saveUserRoles({
            id: data.id,
            subject: { user: { id: userId } },
            context: { key: data.organisationId },
            roles: data.roles,
        });
    };

    const filteredOrganisations = useMemo(() => {
        return organisations.filter(
            (org) =>
                userRolesOrganisation?.context?.key === org.id ||
                !allUserRoles?.some((userRole) => userRole.context?.key === org.id),
        );
    }, [organisations, allUserRoles, userRolesOrganisation]);

    return (
        <Dialog open={open} fullWidth maxWidth="sm">
            <DialogTitleWithClose onClose={handleClose}>{t('addUserRoles')}</DialogTitleWithClose>
            <DialogContent>
                <Stack spacing={2} mt={2}>
                    <SelectComponent name="organisationId" label={t('organisation')} control={control} required>
                        {filteredOrganisations.map((organisation) => (
                            <MenuItem key={organisation.id} value={organisation.id}>
                                {organisation.name}
                            </MenuItem>
                        ))}
                    </SelectComponent>
                    <MultipleSelectComponent
                        name="roles"
                        label={t('roles')}
                        control={control}
                        items={roles?.items.map((item) => ({ id: item.id, name: item.key })) || []}
                        required
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit((data) => onSubmit(data))}
                    id="submit-user-roles"
                >
                    {t('save')}
                </Button>
                <Button onClick={handleClose} color="secondary">
                    {t('cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
