import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateString, Status, useSelectedOrganisation, Widget } from '../../../shared';
import { ISupportCaseInfo } from '../../models';
import { add, isBefore, isSameWeek, set } from 'date-fns';
import { Stack } from '@mui/material';

interface Props {
    supportCase: ISupportCaseInfo;
}

type status = { value: string; status: 'success' | 'warning' | 'error' };

export const RegistrationWarningPanel: FC<Props> = ({ supportCase }) => {
    const { t } = useTranslation();
    const { organisation } = useSelectedOrganisation();

    const postalCode: status = useMemo(() => {
        if (supportCase?.postalCode) {
            return {
                value: supportCase?.postalCode,
                status:
                    supportCase?.postalCode === organisation?.address?.postalCode ||
                    organisation?.preferredPostalCodes?.includes(supportCase?.postalCode)
                        ? 'success'
                        : 'warning',
            };
        }
        return { value: t('notFound'), status: 'warning' };
    }, [supportCase, organisation]);

    const supportPeriod: status = useMemo(() => {
        if (supportCase.activeSupportPeriods.length > 0) {
            const endDate = supportCase.activeSupportPeriods.sort((a, b) => a.endDate.localeCompare(b.endDate))[
                supportCase.activeSupportPeriods.length - 1 || 0
            ].endDate;

            const supportPeriodAlmostOver = isBefore(
                new Date(endDate),
                add(set(new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }), { weeks: 6, days: 1 }),
            );

            return {
                value: `${t('active')} (${formatDateString(endDate)})`,
                status: supportPeriodAlmostOver ? 'warning' : 'success',
            };
        }
        return { value: t('notFound'), status: 'error' };
    }, [supportCase]);

    const lastVisit: status = useMemo(() => {
        const filteredLastRegistrations =
            supportCase.lastRegistrations
                .filter((reg) => reg.supportTypeId !== window.carity.environment.shopSupportTypeId)
                .sort((a, b) => b.dateOfRegistration.localeCompare(a.dateOfRegistration)) || [];
        if (filteredLastRegistrations.length > 0) {
            const visitDate = filteredLastRegistrations[0].dateOfRegistration;
            return {
                value: formatDateString(visitDate),
                status: !isSameWeek(new Date(), new Date(visitDate.slice(0, 10)), { weekStartsOn: 1 })
                    ? 'success'
                    : 'error',
            };
        }
        return { value: t('notFound'), status: 'error' };
    }, [supportCase]);

    return (
        <Widget>
            <Stack spacing={1}>
                <Status label={t('postalCodeLabel')} value={postalCode.value} status={postalCode.status} />
                <Status label={t('supportPeriod')} value={supportPeriod.value} status={supportPeriod.status} />
                <Status label={t('lastVisit')} value={lastVisit.value} status={lastVisit.status} />
            </Stack>
        </Widget>
    );
};
