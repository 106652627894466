import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Dialog, DialogContent, IconButton } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ConfirmModal, SortOrder, StatusCode, Table, useQueryParamsContext } from '../../../shared';
import { useDeleteOrganisationType, useGetOrganisationTypes } from '../../hooks';

export function OrganisationTypesTable() {
    const { t } = useTranslation();
    const {
        queryParams: { page, pageSize, sort },
        setPage,
        setPageSize,
        setSort,
    } = useQueryParamsContext('organisation-types', {
        sort: [{ field: 'name', sort: 'asc' }],
    });
    const [errorDialog, setErrorDialog] = useState({ show: false, message: '' });

    const { isLoading, data } = useGetOrganisationTypes({
        page,
        pageSize,
        sortBy: [sort[0].field],
        sortOrder: sort[0].sort?.toUpperCase() as SortOrder,
    });

    const { mutateAsync: deleteOrganisationType } = useDeleteOrganisationType();

    const onDelete = useCallback(async (id: string) => {
        try {
            await deleteOrganisationType(id);
        } catch (err) {
            if ((err as AxiosError)?.response?.status === StatusCode.CONFLICT) {
                setErrorDialog({ show: true, message: t('organisationTypeDeleteErr') });
            }
        }
    }, []);

    const columns: GridColDef[] = [
        { field: 'name', headerName: t('nameLabel'), minWidth: 150, flex: 1 },
        {
            field: ' ',
            headerName: '',
            width: 120,
            align: 'right',
            sortable: false,
            renderCell: (params: GridCellParams) => (
                <>
                    <IconButton component={Link} to={`/admin/organisation-types/edit/${params.row.id}`} size="large">
                        <EditIcon />
                    </IconButton>
                    <ConfirmModal
                        onConfirm={() => onDelete(params.row.id)}
                        button={
                            <IconButton aria-label="delete" size="large">
                                <DeleteOutlineIcon />
                            </IconButton>
                        }
                        title={t('typeDeleteWarningTitle')}
                        text={t('typeDeleteWarningText')}
                    />
                </>
            ),
        },
    ];

    return (
        <>
            <Table
                rows={data?.data || []}
                columns={columns}
                rowCount={data?.pagination?.size || 0}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                loading={isLoading}
                sortModel={sort}
                onSortChange={setSort}
            />
            <Dialog
                open={errorDialog.show}
                onClose={() => setErrorDialog({ show: false, message: '' })}
                data-testid="error-dialog"
            >
                <DialogContent>{errorDialog.message}</DialogContent>
                <Button onClick={() => setErrorDialog({ show: false, message: '' })} color="primary" autoFocus>
                    {t('ok')}
                </Button>
            </Dialog>
        </>
    );
}
