import { useMemo } from 'react';
import { IIncomeLimit, IIntake } from '../../shared';

export type IntakeIncomeResult = {
    total: number;
    incomeLimits?: (IIncomeLimit & { enabled: boolean })[];
    someIncomeLimitMet?: boolean;
};

export const useIntakeIncomeResult = (intake?: IIntake): IntakeIncomeResult => {
    return useMemo(() => {
        const totalIncome = intake?.income?.reduce((total, { amount }) => total + (amount || 0), 0) || 0;
        const totalExpense = intake?.expenses?.reduce((total, { amount }) => total + (amount || 0), 0) || 0;

        const total = totalIncome - totalExpense;

        const incomeLimits =
            intake?.incomeLimits?.map((incomeLimit) => ({
                ...incomeLimit,
                enabled: incomeLimit.amount >= total,
            })) || [];

        const someIncomeLimitMet = incomeLimits.some(({ enabled }) => enabled);
        return {
            total,
            incomeLimits,
            someIncomeLimitMet,
        };
    }, [intake]);
};
