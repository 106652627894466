import { IReferrerContact } from '../../shared';

export const referrerContactFormatter = (contact?: IReferrerContact): string => {
    if (!contact) return '';

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...values } = contact;
    return Object.values(values)
        .filter((val) => val)
        .join(' - ');
};
