import React, { FC, useEffect } from 'react';
import {
    ISupportTypeFrequenciesForm,
    Page,
    PermissionKeys,
    SelectComponent,
    useHasPermission,
    useSelectedOrganisation,
    Widget,
} from '../../../shared';
import { Button, MenuItem, Stack, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGetOrganisation, useGetSupportTypesFrequencies, useSaveSupportTypesFrequencies } from '../../hooks';
import { Frequency } from '../../../support/enums';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSupportTypeFrequencySchema } from '../../validators';
import { supportTypeFrequencyFromFormMapper, supportTypeFrequencyToFormMapper } from '../../mappers';

export const SupportTypesFrequenciesEditPage: FC = () => {
    const { t } = useTranslation();
    const { organisation: currentOrganisation } = useSelectedOrganisation();

    const { hasPermission } = useHasPermission();
    const hasWritePermission = hasPermission(PermissionKeys.SUPPORT_TYPE_FREQUENCIES_WRITE);

    const { data: organisation } = useGetOrganisation(currentOrganisation?.id);
    const { mutateAsync: saveSupportTypesFrequencies } = useSaveSupportTypesFrequencies();
    const { data: supportTypesFrequencies } = useGetSupportTypesFrequencies({
        organisationId: organisation?.id,
    });

    const form = useForm<ISupportTypeFrequenciesForm>({
        resolver: yupResolver(useSupportTypeFrequencySchema()),
        mode: 'all',
    });

    useEffect(() => {
        if (organisation) {
            form.reset(supportTypeFrequencyToFormMapper(organisation, supportTypesFrequencies?.data));
        }
    }, [organisation, form, supportTypesFrequencies]);

    const onSubmit = async (data: ISupportTypeFrequenciesForm) => {
        if (organisation) {
            await saveSupportTypesFrequencies(supportTypeFrequencyFromFormMapper(organisation, data));
        }
    };

    return (
        <form onSubmit={form.handleSubmit(onSubmit)} noValidate>
            <FormProvider {...form}>
                <Page
                    title={t('supportTypesFrequencies')}
                    actions={[
                        hasWritePermission && (
                            <Button color="primary" variant="contained" onClick={form.handleSubmit(onSubmit)}>
                                {t('save')}
                            </Button>
                        ),
                    ]}
                >
                    {organisation && (
                        <Widget>
                            {form.getValues('frequencies')?.map((item, i) => (
                                <Stack direction="row" spacing={2} key={i} sx={{ mb: 2 }}>
                                    <Controller
                                        name={`frequencies.${i}.supportType.name`}
                                        control={form.control}
                                        defaultValue=""
                                        render={({ field: { onChange, value } }) => (
                                            <TextField
                                                value={value}
                                                onChange={onChange}
                                                label={t('supportType')}
                                                inputProps={{ readOnly: true }}
                                            />
                                        )}
                                    />

                                    <SelectComponent
                                        name={`frequencies.${i}.frequency`}
                                        label={t('frequency')}
                                        control={form.control}
                                        disabled={!hasWritePermission}
                                    >
                                        <MenuItem key={''} value={''} sx={{ height: '35px' }} />
                                        {Object.values(Frequency).map((frequency) => (
                                            <MenuItem key={frequency} value={frequency}>
                                                {t(`${frequency}`)}
                                            </MenuItem>
                                        ))}
                                    </SelectComponent>
                                </Stack>
                            ))}
                        </Widget>
                    )}
                </Page>
            </FormProvider>
        </form>
    );
};
