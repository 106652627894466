import { Box, Drawer, styled } from '@mui/material';
import { FC, ReactNode, useEffect } from 'react';
import { useShowDrawer } from '../../contexts';

type Props = {
    children?: ReactNode;
};

const DrawerContainer = styled(Box)(({ theme }) => ({
    padding: `${theme.spacing(7)} 0`,
}));

export const SideBar: FC<Props> = ({ children }) => {
    const { showDrawer, setShowDrawer, setHasDrawer } = useShowDrawer();
    const handleDrawerToggle = () => {
        setShowDrawer(!showDrawer);
    };

    useEffect(() => {
        setHasDrawer(true);
        return () => setHasDrawer(false);
    }, [setHasDrawer]);

    return (
        <>
            <Drawer
                sx={{ display: { xs: 'block', xl: 'none' } }}
                variant="temporary"
                open={showDrawer}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
            >
                <DrawerContainer onClick={handleDrawerToggle}>{children}</DrawerContainer>
            </Drawer>

            <Drawer sx={{ display: { xs: 'none', xl: 'block' } }} variant="persistent" open>
                <DrawerContainer onClick={handleDrawerToggle}>{children}</DrawerContainer>
            </Drawer>
        </>
    );
};
