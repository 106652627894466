import { Delete, Edit, LockTwoTone } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
    ConfirmModal,
    IParams,
    NoRowsOverlay,
    Page,
    PermissionKeys,
    useGetOrganisations,
    useHasPermission,
    Widget,
} from '../../../shared';
import { DuplicatesWarning, EditOrganisationAccessDialog, SupportCaseTitle } from '../../components';
import { useGetSupportCase, useSaveSupportCase } from '../../hooks';

export function SupportCaseOrganisationsPage() {
    const { t } = useTranslation();
    const { id } = useParams<keyof IParams>() as IParams;
    const navigate = useNavigate();
    const { hasPermission } = useHasPermission();
    const hasWritePermission = hasPermission(PermissionKeys.SUPPORT_CASES_WRITE);
    const [editOrganisationAccessDialog, setEditOrganisationAccessDialog] = useState(false);

    const { data: supportCase } = useGetSupportCase(id);

    // get information of the selected organisations
    const { data: organisationsList } = useGetOrganisations({
        ids: supportCase?.organisations ? supportCase.organisations.map((org) => org.id as string) : [],
        pageSize: supportCase?.organisations?.length,
    });
    const organisations = organisationsList?.data || [];

    const { mutateAsync: saveSupportCase } = useSaveSupportCase();
    const removeOrganisationsAccess = async (id: string | undefined) => {
        if (supportCase) {
            supportCase.organisations = supportCase.organisations.filter((org) => org.id != id);
            await saveSupportCase(supportCase);
        }
    };

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: t('nameLabel'),
            minWidth: 200,
            flex: 1,
            sortable: false,
        },
        ...(hasWritePermission
            ? ([
                  {
                      field: 'edit',
                      headerName: ' ',
                      sortable: false,
                      minWidth: 100,
                      align: 'right',
                      flex: 1,
                      renderCell: ({ row: organisation, id }: GridCellParams) => {
                          return (
                              <>
                                  {organisations.length > 1 && (
                                      <ConfirmModal
                                          onConfirm={() => removeOrganisationsAccess(organisation.id)}
                                          button={
                                              <IconButton data-testid={`organisation-${id}-delete`}>
                                                  <Delete />
                                              </IconButton>
                                          }
                                          title={t('removeAccessTitle')}
                                          text={
                                              <>
                                                  <b>{organisation.name}</b> {t('removeAccessText')}
                                              </>
                                          }
                                      />
                                  )}
                              </>
                          );
                      },
                  },
              ] as GridColDef[])
            : ([] as GridColDef[])),
    ];

    return (
        <Page onBack={() => navigate(-1)} title={supportCase && <SupportCaseTitle supportCase={supportCase} />}>
            <DuplicatesWarning supportCaseId={id} />
            <Widget
                icon={<LockTwoTone color="primary" />}
                title={t('organisationsWithAccess')}
                hasTable
                actions={
                    hasWritePermission && (
                        <IconButton
                            onClick={() => setEditOrganisationAccessDialog(true)}
                            data-testid="edit-permissions-btn"
                        >
                            <Edit />
                        </IconButton>
                    )
                }
            >
                <DataGrid
                    rows={organisations}
                    columns={columns}
                    autoHeight
                    hideFooter
                    disableColumnFilter={true}
                    slots={{ noRowsOverlay: NoRowsOverlay }}
                />
                <EditOrganisationAccessDialog
                    open={editOrganisationAccessDialog}
                    onClose={() => setEditOrganisationAccessDialog(false)}
                />
            </Widget>
        </Page>
    );
}
