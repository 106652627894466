import { CreditCardTwoTone } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { IParams, NoRowsOverlay, Page, PermissionKeys, useHasPermission, Widget } from '../../../shared';
import {
    DownloadCardAction,
    DuplicatesWarning,
    LinkCardAction,
    SupportCaseTitle,
    UnlinkCardAction,
} from '../../components';
import { useGetSupportCase } from '../../hooks';

export const SupportCaseCardsPage: FC = () => {
    const { t } = useTranslation();
    const { hasPermission } = useHasPermission();
    const navigate = useNavigate();

    const { id } = useParams<keyof IParams>() as IParams;
    const { data: supportCase } = useGetSupportCase(id);

    const hasWritePermission = hasPermission(PermissionKeys.SUPPORT_CASES_WRITE);

    const columns: GridColDef[] = [
        {
            field: 'code',
            headerName: t('code'),
            minWidth: 200,
            flex: 1,
            sortable: false,
        },
        ...(hasWritePermission
            ? ([
                  {
                      field: 'edit',
                      headerName: ' ',
                      sortable: false,
                      minWidth: 100,
                      align: 'right',
                      flex: 1,
                      renderCell: ({ row }: GridCellParams) => {
                          return (
                              <Stack spacing={2} direction="row">
                                  {supportCase && hasWritePermission && (
                                      <UnlinkCardAction supportCase={supportCase} card={row} />
                                  )}
                                  {supportCase && <DownloadCardAction cardId={row.id} />}
                              </Stack>
                          );
                      },
                  },
              ] as GridColDef[])
            : ([] as GridColDef[])),
    ];

    return (
        <>
            {supportCase && (
                <Page onBack={() => navigate(-1)} title={supportCase && <SupportCaseTitle supportCase={supportCase} />}>
                    <DuplicatesWarning supportCaseId={id} />
                    <Widget
                        icon={<CreditCardTwoTone color="primary" />}
                        title={t('cards')}
                        hasTable
                        actions={hasWritePermission && <LinkCardAction supportCase={supportCase} />}
                    >
                        <DataGrid
                            rows={supportCase?.cards || []}
                            columns={columns}
                            autoHeight
                            hideFooter
                            disableColumnFilter={true}
                            slots={{ noRowsOverlay: NoRowsOverlay }}
                        />
                    </Widget>
                </Page>
            )}
        </>
    );
};
