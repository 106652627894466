import * as languages from '@cospired/i18n-iso-languages';
import { Checkbox, FormControlLabel, Grid, MenuItem } from '@mui/material';
import * as countries from 'i18n-iso-countries';
import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    AutocompleteComponent,
    ControlledDatePicker,
    ControlledInputWithMask,
    FormGrid,
    InputTextField,
    SelectComponent,
} from '../../../shared';
import { Gender, SupporteeRelation } from '../../enums';
import { ObjectToArrayMapper } from '../../mappers';

interface Props {
    field?: string;
    vertical?: boolean;
}

export const SupporteeInputs: FC<Props> = ({ field, vertical }) => {
    const { t, i18n } = useTranslation();
    const prefix = field ? `${field}.` : '';
    const { control, watch, setValue, trigger } = useFormContext();
    const watchAnonymous = watch(`${prefix}anonymous`);

    return (
        <Grid container spacing={2} direction="column">
            <Grid item>
                <FormControlLabel
                    control={
                        <Controller
                            defaultValue={false}
                            name={`${prefix}anonymous`}
                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <Checkbox
                                        checked={value}
                                        onChange={(event) => {
                                            onChange(event);
                                            if (event.target.checked) {
                                                setValue(`${prefix}firstName`, '');
                                                setValue(`${prefix}lastName`, '');
                                                setValue(`${prefix}nationalSecurityNumber`, '');
                                                trigger();
                                            }
                                        }}
                                        data-testid={`${prefix}anonymous-checkbox`}
                                    />
                                );
                            }}
                        />
                    }
                    label={`${t('anonymousLabel')}`}
                />
            </Grid>
            <Grid item xs={12}>
                <FormGrid
                    xs={12}
                    sm={!vertical && 6}
                    lg={!vertical && 3}
                    xl={!vertical && 2}
                    containerProps={{ pl: 2 }}
                    sx={{ '&.MuiGrid-root': { pr: vertical ? 0 : 2 } }}
                >
                    <InputTextField
                        name={`${prefix}firstName`}
                        label={t('firstNameLabel')}
                        control={control}
                        disabled={watchAnonymous}
                        required={!watchAnonymous}
                    />

                    <InputTextField
                        name={`${prefix}lastName`}
                        label={t('lastNameLabel')}
                        control={control}
                        disabled={watchAnonymous}
                        required={!watchAnonymous}
                    />

                    <AutocompleteComponent
                        minWidth={0}
                        name={`${prefix}relation`}
                        label={t('relationLabel')}
                        control={control}
                        defaultValue={null}
                        options={Object.values(SupporteeRelation)}
                        getOptionLabel={(option) =>
                            Object.values(SupporteeRelation).includes(option) ? t(option.toLowerCase()) : option
                        }
                    />

                    <SelectComponent name={`${prefix}gender`} label={t('genderLabel')} control={control} minWidth={0}>
                        <MenuItem value={''}>{t('unknown')}</MenuItem>
                        {Object.values(Gender).map((gender) => (
                            <MenuItem value={gender} key={gender}>
                                {t(gender.toLowerCase())}
                            </MenuItem>
                        ))}
                    </SelectComponent>
                </FormGrid>
            </Grid>

            <Grid item>
                <FormGrid
                    xs={12}
                    sm={!vertical && 6}
                    lg={!vertical && 3}
                    xl={!vertical && 2}
                    containerProps={{ pl: 2 }}
                    sx={{ '&.MuiGrid-root': { pr: vertical ? 0 : 2 } }}
                >
                    <ControlledDatePicker
                        name={`${prefix}dateOfBirth`}
                        control={control}
                        label={t('dateOfBirthLabel')}
                    />

                    <ControlledInputWithMask
                        name={`${prefix}nationalSecurityNumber`}
                        control={control}
                        mask="**.**.**-***.**"
                        placeholder={'XX.XX.XX-XXX.XX'}
                        label={t('nationalSecurityNumberLabel')}
                        disabled={watchAnonymous}
                    />

                    <AutocompleteComponent
                        minWidth={0}
                        name={`${prefix}nationality`}
                        label={t('nationalityLabel')}
                        control={control}
                        options={ObjectToArrayMapper(countries.getNames(i18n.language.slice(0, 2)))}
                        defaultValue={null}
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={(option, value) =>
                            value ? option.code === value.code || option.name == value.name : false
                        }
                    />

                    <AutocompleteComponent
                        minWidth={0}
                        name={`${prefix}language`}
                        label={t('languageLabel')}
                        control={control}
                        defaultValue={null}
                        options={ObjectToArrayMapper(languages.getNames(i18n.language.slice(0, 2)))}
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={(option, value) =>
                            value ? option.code === value.code || option.name == value.name : false
                        }
                    />
                </FormGrid>
            </Grid>
        </Grid>
    );
};
