import React, { FC, Fragment } from 'react';
import { ContactType, FormCardSectionComponent, FormGrid, InputTextField, SelectComponent } from '../../../shared';
import { Button, IconButton, MenuItem } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useFormContext } from 'react-hook-form';

export const OrganisationContactInputs: FC = () => {
    const { t } = useTranslation();
    const { control } = useFormContext();

    const {
        fields: contacts,
        append,
        remove,
    } = useFieldArray({
        control,
        name: 'contacts',
    });

    return (
        <FormCardSectionComponent
            title={t('contactInformation')}
            footer={
                <Button
                    data-testid="add-contact"
                    onClick={() => append({ type: ContactType.Email, data: '' })}
                    color="secondary"
                >
                    + {t('contactsAdd')}
                </Button>
            }
        >
            {contacts.map((contact, index) => (
                <Fragment key={contact.id}>
                    <FormGrid>
                        <SelectComponent name={`contacts.${index}.type`} label={t('typeLabel')} control={control}>
                            {Object.entries(ContactType).map(([key, value]) => (
                                <MenuItem value={value} key={value}>
                                    {key}
                                </MenuItem>
                            ))}
                        </SelectComponent>
                        <InputTextField
                            name={`contacts.${index}.data`}
                            label={t('contactLabel')}
                            control={control}
                            required={true}
                            sx={{ width: '400px' }}
                        />
                        <IconButton aria-label="delete" onClick={() => remove(index)} size="large">
                            <CloseIcon />
                        </IconButton>
                    </FormGrid>
                </Fragment>
            ))}
        </FormCardSectionComponent>
    );
};
